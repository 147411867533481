import Carousel from './Carousel';
import React, { useEffect, useState } from 'react';
import styles from './styles/achievements.module.scss';
import json_achievements_en from './jsons/en/achievements_en.json';
import json_achievements_pt from './jsons/pt/achievements_pt.json';

const Achievements = () => {

    //////////////////////////////////////////////////////////////////////////////////////
    // VARIABLES
    //////////////////////////////////////////////////////////////////////////////////////
    // json data
    const [language, set_language] = useState(localStorage.getItem("language"));
    const json_data = language === "EN" ? json_achievements_en : json_achievements_pt;
    const [can_render_carousel, set_can_render_carousel] = useState(false);

    // listen for language changes
    window.addEventListener('language', () => {
        set_language(localStorage.getItem("language"));
    });
    
    useEffect(() => {
        set_can_render_carousel(true);
    }, []);

    return (
        <div className = {styles.root}>
            <div className = {styles.suptitle}>
                {json_data.suptitle}
            </div>

            <Carousel 
                type = {"frosted_glass"}
                data = {json_data}
                can_render_carousel = {can_render_carousel}
                background_color = {"rgba(150, 150, 150, 0.3)"}
            />

        </div>
    );
}

export default Achievements;