import React, { useState } from 'react';
import styles from './styles/applications.module.scss';
import json_applications_en from './jsons/en/applications_en.json';
import json_applications_pt from './jsons/pt/applications_pt.json';

const Applications = () => {

    //////////////////////////////////////////////////////////////////////////
    // VARIABLES
    //////////////////////////////////////////////////////////////////////////
    // json data
    const [language, set_language] = useState(localStorage.getItem("language"));
    const json_data = language === "EN" ? json_applications_en : json_applications_pt;
    const [window_width, set_window_width] = useState(window.innerWidth);
    const navbar_height = parseInt(getComputedStyle(document.body).getPropertyValue('--navbar_height').split("px")[0]);
    const [current_scroll_position, set_current_scroll_position] = useState(localStorage.getItem("current_scroll_position") !== null ? parseInt(localStorage.getItem("current_scroll_position")) : 0);

    // listen for language changes
    window.addEventListener('language', () => {
        set_language(localStorage.getItem("language"));
    });

    // listen for scroll position changes
    window.addEventListener('current_scroll_position_change', () => {
        set_current_scroll_position(localStorage.getItem("current_scroll_position"));
    });

    // listen for window width changes
    window.addEventListener('resize', () => { 
        set_window_width(window.innerWidth); 
    });

    const handle_click = () => {
        let target_top = parseInt(document.getElementById("references").getBoundingClientRect().top);
        if(target_top === 0)
            return

        let new_scroll_position = current_scroll_position + target_top - navbar_height - 20;
        window.scroll({top: new_scroll_position, left: 0, behavior: 'smooth' });
        
        set_current_scroll_position(new_scroll_position);
    }

    return ( 
        <div className = {styles.root}>
            <div className = {styles.main_suptitle}>
                {json_data.suptitle}
            </div>
            <div className = {styles.main_title}>
                {json_data.title}
            </div>
            <div 
                className = {styles.applications_div}
                style = {{
                    gap: (window_width < 860 ? "100px" : "75px"),
                }}>
                {json_data.items.map((item) => (
                    <div 
                        key = {json_data.items.indexOf(item)}
                        className = {styles.feature_super_div}
                        style = {{
                            flexDirection: (item.image_side === "left" ? "row" : "row-reverse")
                        }}>
                        <div 
                            className = {styles.feature_image_div}
                            style = {{
                                flexBasis: (window_width < 860 ? "100%" : "45%"),
                            }}>
                            <div 
                                className = {styles.feature_image} 
                                style = {{backgroundImage: `url(${require(`${item.image}`)})`, backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover"}}>
                            </div>
                        </div>
                        <div className = {styles.feature_text_div}>
                            <div 
                                className = {styles.suptitle}
                                style = {{
                                    textAlign: (window_width < 860 ? "center" : "left"),
                                }}>
                                {item.suptitle}
                            </div>
                            <div 
                                className = {styles.title}
                                style = {{
                                    textAlign: (window_width < 860 ? "center" : "left"),
                                }}>
                                {item.title}
                            </div>
                            <div className = {styles.description_super_div}>
                                <span 
                                    className = {styles.description}
                                    dangerouslySetInnerHTML = {{__html: item.description}} />
                                <span 
                                    onClick = {() => handle_click()}
                                    className = {styles.reference_indicator}>
                                    <sup>{json_data.items.indexOf(item) + 1}</sup>
                                </span>
                            </div>
                        </div>
                    </div>
                ))}
            </div>

        </div>
    );
}

export default Applications;