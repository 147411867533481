import Carousel from './Carousel';
import React, { useEffect, useState } from 'react';
import styles from './styles/publications_and_patents.module.scss';
import json_publications_and_patents_en from './jsons/en/publications_and_patents_en.json';
import json_publications_and_patents_pt from './jsons/pt/publications_and_patents_pt.json';

const PublicationsAndPatents = () => {

    //////////////////////////////////////////////////////////////////////////////////////
    // VARIABLES
    //////////////////////////////////////////////////////////////////////////////////////
    // json data
    const [language, set_language] = useState(localStorage.getItem("language"));
    const json_data = language === "EN" ? json_publications_and_patents_en : json_publications_and_patents_pt;
    const [can_render_carousel, set_can_render_carousel] = useState(false);

    // listen for language changes
    window.addEventListener('language', () => {
        set_language(localStorage.getItem("language"));
    });

    useEffect(() => {
        set_can_render_carousel(true);
    }, []);

    return (
        <div className = {styles.root}>
            <div className = {styles.suptitle}>
                {json_data.suptitle}
            </div>

            <div className = {styles.root_background_fade}/>

            <Carousel 
                type = {"alternative"}
                data = {json_data}
                can_render_carousel = {can_render_carousel}
                background_color = {"rgba(150, 150, 150, 0.3)"}
            />

        </div>
    );
}

export default PublicationsAndPatents;