import React from 'react';
import useMeasure from "react-use-measure";
import styles from './styles/company.module.scss';
import { useEffect, useState, useRef } from 'react';
import json_company_en from './jsons/en/company_en.json';
import json_company_pt from './jsons/pt/company_pt.json';
import company_video from './resources/videos/intro_final.mp4';

const Company = () => {

    //////////////////////////////////////////////////////////////////////////
    // VARIABLES
    //////////////////////////////////////////////////////////////////////////
    // json data
    const [sideItemsRef, sideItemsDimensions] = useMeasure();
    const [language, set_language] = useState(localStorage.getItem("language"));
    const json_data = language === "EN" ? json_company_en : json_company_pt;
    const [window_width, set_window_width] = useState(window.innerWidth);

    // add event listeners
    useEffect(() => {

        // listen for language changes
        function language_changes () {
            set_language(localStorage.getItem("language"));
        }
        window.addEventListener('language', language_changes);

        // listen for window width changes
        function resize () {
            set_window_width(window.innerWidth); 
        }
        window.addEventListener('resize', resize);

        // do some cleanup (i.e., remove the event listeners if this component ends up being unmounted)
        return(() => {
            window.removeEventListener('language', language_changes);
            window.removeEventListener('resize', resize);
        })

    }, []);

    return (
        <div className = {styles.root}>
            <div className = {styles.suptitle}>
                {json_data.suptitle}
            </div>
            <div className = {styles.title}>
                {json_data.title}
            </div>
            <div className = {styles.company_images_and_description}>
                
                <video 
                    className = {styles.company_image}
                    autoPlay muted playsInline loop
                    width = {"100%"}>
                    <source src = {company_video} type = "video/mp4"/>
                </video>
                
                <div 
                    className = {styles.company_description_super_div}
                    ref = {sideItemsRef}>
                    {json_data.items.map((item, index) => (
                        <div 
                            key = {index}
                            className = {styles.company_description_div}
                            style = {{
                                flexBasis: (window_width < 1000 ? "20%" : ""),
                                minWidth: (window_width < 1000 ? "160px" : "10px"),
                            }}>
                                <div className = {styles.company_description_title}>{item.title}</div>
                                <div className = {styles.company_description_text_super}>
                                    <div className = {styles.vertical_align}>
                                        <div className = {styles.company_description_text} dangerouslySetInnerHTML = {{__html: item.description}} />
                                    </div>
                                </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Company;