import React from 'react';
import { useState } from 'react';
import styles from './styles/roadmap.module.scss';
import json_roadmap_en from './jsons/en/roadmap_en.json';
import json_roadmap_pt from './jsons/pt/roadmap_pt.json';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';

const Roadmap = () => {

    //////////////////////////////////////////////////////////////////////////
    // VARIABLES
    //////////////////////////////////////////////////////////////////////////
    // json data
    const [language, set_language] = useState(localStorage.getItem("language"));
    const json_data = language === "EN" ? json_roadmap_en : json_roadmap_pt;
    const [window_width, set_window_width] = useState(window.innerWidth);

    // listen for language changes
    window.addEventListener('language', () => {
        set_language(localStorage.getItem("language"));
    });

    // listen for window width changes
    window.addEventListener('resize', () => { 
        set_window_width(window.innerWidth); 
    });

    return (
        <div className = {styles.root}>
            <div className = {styles.suptitle}>
                {json_data.suptitle}
            </div>
            <div className = {styles.title}>
                {json_data.title}
            </div>

            <div className = {styles.roadmap_div}>

                <div className = {styles.roadmap_div_background} />
                
                <div 
                    className = {styles.roadmap_div_background_fade}
                    style = {{
                        left: 0,
                        background: "linear-gradient(to left, rgba(255, 255, 255, 1.0), rgba(0, 0, 0, 0))",
                    }}/>

                <div 
                    className = {styles.roadmap_div_background_fade}
                    style = {{
                        right: 0,
                        background: "linear-gradient(to right, rgba(255, 255, 255, 1.0), rgba(0, 0, 0, 0))",
                    }}/>

                <Timeline position = "alternate">
                    {json_data.items.map((item) => (
                        <TimelineItem key = {json_data.items.indexOf(item)}>
                            <TimelineContent 
                                style = {{
                                    marginTop: "35px",
                                    textAlign: (json_data.items.indexOf(item) % 2 === 0 ? "right" : "left"),
                                }}>
                                <div 
                                    className = {styles.item_type}
                                    style = {{
                                        fontSize: (window_width > 500 ? "13.5px" : "11.5px"),
                                    }}>
                                    {item.type}
                                </div>
                                <div 
                                    className = {styles.item_title}
                                    style = {{
                                        fontSize: (window_width > 500 ? "15.5px" : "13.5px"),
                                    }}>
                                    {item.title}
                                </div>
                            </TimelineContent>
                            <TimelineSeparator>
                                <TimelineConnector 
                                    style = {{ 
                                        height: "30px" 
                                    }}/>
                                    <TimelineDot style = {{
                                        backgroundColor: "var(--secondary_colour)",
                                    }}>
                                        <div 
                                            className = {styles.roadmap_icon}
                                            style = {{
                                                backgroundImage: `url(${require(`${item.icon}`)})`, 
                                            }}/>                                
                                    </TimelineDot>
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineOppositeContent
                                sx={{ m: 'auto 0' }}
                                align = "left"
                                variant = "body2"
                                color = "text.secondary"
                                >
                                <div 
                                    className = {styles.item_description} 
                                    style = {{
                                        marginTop: "30px",
                                        textAlign: (json_data.items.indexOf(item) % 2 === 0 ? "left" : "right"),
                                    }}>
                                    {item.description}
                                </div>
                                
                            </TimelineOppositeContent>
                        </TimelineItem>
                    ))}

                    {/* empty item just to add another separator at the end */}
                    <TimelineItem style = {{marginBottom: "-50px"}}>
                        <TimelineContent />
                        <TimelineSeparator style = {{ height: "30px" }}>
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineOppositeContent />
                    </TimelineItem>

                </Timeline>
            </div>
            
        </div>
    );
}

export default Roadmap;