import { send } from 'emailjs-com';
import TextField from '@mui/material/TextField';
import React, { useEffect, useState } from 'react';
import styles from './styles/contact_us.module.scss';
import json_contact_us_en from './jsons/en/contact_us_en.json';
import json_contact_us_pt from './jsons/pt/contact_us_pt.json';
import CircularProgress from '@material-ui/core/CircularProgress';

const ContactUs = () => {

    //////////////////////////////////////////////////////////////////////////////////////
    // VARIABLES
    //////////////////////////////////////////////////////////////////////////////////////
    // json data
    const [language, set_language] = useState(localStorage.getItem("language"));
    const [status_message, set_status_message] = useState("");
    const json_data = language === "EN" ? json_contact_us_en : json_contact_us_pt;
    const [window_width, set_window_width] = useState(window.innerWidth);
    const [active_choice_index, set_active_choice_index] = useState(0);
    const [is_loading, set_is_loading] = useState(false);

    // listen for language changes
    window.addEventListener('language', () => {
        set_language(localStorage.getItem("language"));

        let new_json_data = localStorage.getItem("language") === "EN" ? json_contact_us_en : json_contact_us_pt
        
        if(name_helper_text !== "")
            set_name_helper_text(new_json_data.form_name_error);

        if(email_helper_text !== "")
            set_email_helper_text(new_json_data.form_email_error);

        if(message_helper_text !== "")
            set_message_helper_text(new_json_data.form_message_error);
    });

    // name textfield states
    const [name, set_name] = useState("");
    const [name_helper_text, set_name_helper_text] = useState("");

    // email textfield states
    const [email, set_email] = useState("");
    const [email_helper_text, set_email_helper_text] = useState("");

    // message textfield states
    const [message, set_message] = useState("");
    const [message_helper_text, set_message_helper_text] = useState("");

    const base_input_props_style = { 
        color: "rgba(115, 115, 115, 1.0)",
        backgroundColor: "white",
        borderRadius: "8px",
        fontSize: 13.5,
        border: "",
    };

    const base_input_label_style = { 
        color: "rgba(115, 115, 115, 1.0)",
        fontSize: 13.5,
        backgroundColor: "",
        fontFamily: "light",
        paddingLeft: "",
        paddingRight: "",
    };

    useEffect(() => {

        window.addEventListener('resize', () => { set_window_width(window.innerWidth); });

    }, []);

    const handle_click = () => {

        if(name === "")
            set_name_helper_text(json_data.form_name_error);
        else
            set_name_helper_text("");
        
        if(email === "")
            set_email_helper_text(json_data.form_email_error);
        else
            set_email_helper_text("");

        if(message === "")
            set_message_helper_text(json_data.form_message_error);
        else
            set_message_helper_text("");

        if(name !== "" && email !== "" && message !== ""){
        
            set_is_loading(true);

            const stuff_to_send = {
                "name": name,
                "email": email,
                "contact_type": json_data.contact_choice_items[active_choice_index].title,
                "message": message,
            }
            
            send(
                'service_aeg5eni',
                'template_x1r2mht',
                stuff_to_send,
                'QGTYfXWYqwQOu0m4F'
            ).then((response) => {
                set_is_loading(false);
                console.log('SUCCESS!', response.status, response.text);

                set_status_message(json_data.on_success_text);

                setTimeout(() => {
                    set_status_message("");
                }, 3000);
            })
            .catch((err) => {
                set_is_loading(false);
                console.log('FAILED...', err);

                set_status_message(json_data.on_error_text);

                setTimeout(() => {
                    set_status_message("");
                }, 3000);
            });
        }
    }

    return ( 
        <div className = {styles.root}>
            <div className = {styles.root_background} />
            <div className = {styles.contact_us_div}>
                <div className = {styles.contact_us_inner_div}>
                    <div 
                        className = {styles.contact_us_form_div}
                        style = {{
                            minWidth: (window_width > 650 ? "500px" : (window_width > 500 ? "350px" : "150px")),
                        }}>
                        <div className = {styles.suptitle}>
                            {json_data.suptitle}
                        </div>
                        <div className = {styles.title}>
                            {json_data.title}
                        </div>
                        <div 
                            className = {styles.contact_us_choices_div}
                            style = {{
                                height: (window_width > 500 ? "150px" : (window_width > 400 ? "100px" : "75px")),
                            }}>
                            {json_data.contact_choice_items.map((item) => (
                                <div 
                                    key = {json_data.contact_choice_items.indexOf(item)}
                                    className = {styles.contact_us_choice_div}
                                    onClick = {() => set_active_choice_index(json_data.contact_choice_items.indexOf(item))}
                                    style = {{
                                        opacity: (active_choice_index === json_data.contact_choice_items.indexOf(item) ? "1.0" : "0.55"),
                                        border: (active_choice_index === json_data.contact_choice_items.indexOf(item) ? "2px solid var(--secondary_colour)" : "2px solid rgba(200, 200, 200, 1.0)"),
                                    }}>
                                    <div 
                                        className = {styles.contact_us_choice_background_image}
                                        style = {{
                                            backgroundImage: `url(${require(`${item.image}`)})`, 
                                        }}/>
                                    <div 
                                        className = {styles.contact_us_choice_fade}
                                        style = {{
                                            backgroundImage: (active_choice_index === json_data.contact_choice_items.indexOf(item) ? "linear-gradient(to bottom, rgba(196, 44, 39, 0.1) 50%, rgba(196, 44, 39, 0.1))" : "linear-gradient(to bottom, rgba(70, 70, 70, 0.1) 50%, rgba(70, 70, 70, 1.0))"),
                                            //backgroundImage: "linear-gradient(to bottom, rgba(230, 230, 230, 0.1) 50%, rgba(230, 230, 230, 1.0))",
                                            //backgroundColor: (active_choice_index === json_data.contact_choice_items.indexOf(item) ? "rgb(137, 39, 35)" : "rgba(200, 200, 200, 1.0)"),
                                        }}>
                                        <div className = {styles.vertical_align}>
                                            <div 
                                                className = {styles.contact_us_choice_text}
                                                style = {{
                                                    fontSize: (window_width > 400 ? "13.5px" : "10px"),
                                                    color: (active_choice_index === json_data.contact_choice_items.indexOf(item) ? "var(--secondary_colour)" : "rgba(200, 200, 200, 1.0)"),
                                                    bottom: (window_width > 500 ? "10px" : "6px"),
                                                }}>
                                                {item.title}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div 
                            className = {styles.text_field_div}
                            style = {{
                                marginTop: "30px",
                            }}>
                            <TextField 
                                onChange = {(e) => set_name(e.target.value)}
                                className = {styles.text_field} 
                                id = "outlined-basic" 
                                error = {name_helper_text !== "" ? true : false}
                                InputProps={{ style: base_input_props_style }}
                                InputLabelProps={{ style: base_input_label_style }}
                                helperText = {name_helper_text}
                                label = {json_data.form_name_hint} 
                                variant = "outlined" 
                                type = "text" />
                        </div>
                        <div className = {styles.text_field_div}>
                            <TextField 
                                onChange = {(e) => set_email(e.target.value)}
                                className = {styles.text_field} 
                                id = "outlined-basic" 
                                error = {email_helper_text !== "" ? true : false}
                                InputProps={{ style: base_input_props_style }}
                                InputLabelProps={{ style: base_input_label_style }}
                                helperText = {email_helper_text}
                                label = {json_data.form_email_hint} 
                                variant = "outlined" 
                                type = "text" />
                        </div>
                        <div className = {styles.text_field_div}>
                            <TextField 
                                onChange = {(e) => set_message(e.target.value)}
                                className = {styles.text_field} 
                                id = "outlined-basic" 
                                multiline
                                rows = {4}
                                error = {message_helper_text !== "" ? true : false}
                                InputProps={{ style: base_input_props_style }}
                                InputLabelProps={{ style: base_input_label_style }}
                                helperText = {message_helper_text}
                                label = {json_data.form_message_hint} 
                                variant = "outlined" 
                                type = "text" />
                        </div>
                        <div 
                            className = {styles.contact_us_button}
                            onClick = {() => handle_click()}>
                            <div 
                                className = {styles.contact_us_button_text}
                                style = {{
                                    opacity: (is_loading ? "0.0" : "1.0"),
                                }}>
                                {json_data.form_button_text}
                            </div>
                            <div className = {styles.contact_us_button_circular_progress_div}>
                                <CircularProgress 
                                    size = {20}
                                    style = {{
                                        opacity: (is_loading ? "1.0" : "0.0"),
                                        color: "white",
                                    }} />
                            </div>
                        </div>
                        <div 
                            className = {styles.status_message_div}
                            style = {{
                                visibility: (status_message !== "" ? "visible" : "hidden"),
                                opacity: (status_message !== "" ? "1.0" : "0.0"),
                                marginTop: (status_message !== "" ? "15px" : "0px"),
                            }}>
                            {status_message}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ContactUs;