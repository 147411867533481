import React, { useState } from 'react';
import styles from './styles/farewell_message.module.scss';
import json_farewell_message_en from './jsons/en/farewell_message_en.json';
import json_farewell_message_pt from './jsons/pt/farewell_message_pt.json';

const FarewellMessage = () => {

    //////////////////////////////////////////////////////////////////////////////
    // VARIABLES
    //////////////////////////////////////////////////////////////////////////////
    // json data
    const [language, set_language] = useState(localStorage.getItem("language"));
    const json_data = language === "EN" ? json_farewell_message_en : json_farewell_message_pt;

    // listen for language changes
    window.addEventListener('language', () => {
        set_language(localStorage.getItem("language"));
    });

    return (
        <div className = {styles.root}>
            <span 
                className = {styles.text}
                style = {{
                    color: "rgba(90, 90, 90, 1.0)",
                }}>
                {json_data.first_part}
            </span>
            <span 
                className = {styles.text}
                style = {{
                    color: "var(--secondary_colour)",
                }}>
                {json_data.second_part}
            </span>
        </div>
    );
}

export default FarewellMessage;