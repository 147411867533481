import React from 'react';
import { useHistory } from "react-router-dom";
import arrow from './resources/icons/arrow.png';
import styles from './styles/bread_crumbs.module.scss';

const BreadCrumbs = (props) => {

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // VARIABLES
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // ♥ or ♡ 
    const generic_message = (props.bread_crumbs_generic_message)
    
    let current_url = (window.location.href[window.location.href.length - 1] === "/") ? (window.location.href.split("#")[0]) : (window.location.href.split("#")[0] + "/");
    current_url = current_url.replace("http://", "").replace("https://", "");

    if(current_url.includes("localhost")){
        const to_ignore = current_url.split(":")[1].split("/")[0]
        current_url = "home" + current_url.replace("localhost:", "").replace(to_ignore, "").slice(0, -1).replace(/_/g, " ")
    }
    
    else{
        current_url = "home" + current_url.replace("www.", "").replace("https://", "").replace("http://", "").replace("deepneuronic.com", "").replace("deepneuronicdev.netlify.app", "").slice(0, -1).replace(/_/g, " ")
    }
    
    let bread_crumbs = current_url.split("/")
    if(bread_crumbs.length === 1)
        bread_crumbs = [generic_message]

    let history = useHistory();

    ////////////////////////////////////////////////////////////////////////////
    // AUXILIARY FUNCTIONS
    ////////////////////////////////////////////////////////////////////////////
    // -------------------------------------------------------------------------
    // redirect the user to the chosen page
    // -------------------------------------------------------------------------
    const handle_click = (bread_crumbs, bread_crumb_index) => {

        if(JSON.stringify(bread_crumbs) === JSON.stringify([generic_message]))
            return;

        let path = "";

        for(let i = 0; i < (bread_crumb_index + 1); i++){
            path += bread_crumbs[i].replace("home", "").replace(" ", "_") + "/";
        }

        history.push(path);
    }

    return (
        <div className = {styles.desktop_root}>
            {bread_crumbs.map((bread_crumb) => (
                <div className = {styles.bread_crumb_div}>
                    <div className = {styles.vertical_align}>
                        <div className = {styles.bread_crumb_text} onClick = {() => handle_click(bread_crumbs, bread_crumbs.indexOf(bread_crumb))} style = {((bread_crumbs.indexOf(bread_crumb) === (bread_crumbs.length - 1) && (bread_crumbs.length !== 1)) ? {fontWeight: "bold", opacity: 0.7} : {})}>
                            {bread_crumb}
                        </div>
                    </div>
                    <div className = {styles.vertical_align}>
                        <div className = {styles.bread_crumb_arrow_div} style = {(bread_crumbs.indexOf(bread_crumb) === (bread_crumbs.length - 1)) ? {display: "none"} : {display: "visible", opacity: "0.5"}}>
                            <img className = {styles.arrow_image} src = {arrow} alt = "Arrow" />
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default BreadCrumbs;