import React, { useState } from 'react';
import styles from './styles/team.module.scss';
import json_team_en from './jsons/en/team_en.json';
import json_team_pt from './jsons/pt/team_pt.json';

const Team = () => {

    //////////////////////////////////////////////////////////////////////////
    // VARIABLES
    //////////////////////////////////////////////////////////////////////////
    // json data
    const [language, set_language] = useState(localStorage.getItem("language"));
    const json_data = language === "EN" ? json_team_en : json_team_pt;
    const [window_width, set_window_width] = useState(window.innerWidth);
    const max_width = parseInt(getComputedStyle(document.body).getPropertyValue('--max_width').split("px")[0]);

    const team_members = json_data.items.filter((item) => !isTeamMemberAnAdvisor(item));
    const team_advisors = json_data.items.filter((item) => isTeamMemberAnAdvisor(item));
    const members = [team_members, team_advisors];

    // listen for language changes
    window.addEventListener('language', () => {
        set_language(localStorage.getItem("language"));
    });

    // listen for window width changes
    window.addEventListener('resize', () => { 
        set_window_width(window.innerWidth); 
    });

    const handle_click = (url) => {
        window.open(url, "__blank");
    }

    function isTeamMemberAnAdvisor (team_member) {

        if(!team_member.role.toLowerCase().replace("consultor", "advisor").includes("advisor") || team_member.role === "Consultor Financeiro")
            return(false);
        
        return(true);
    }

    return ( 
        <div className = {styles.root}>
            <div 
                className = {styles.root_background}
                style = {{
                    marginLeft: (window_width <= max_width ? "0": "calc(((100vw - var(--max_width)) / 2) * -1)"),
                }}/>
            <div 
                className = {styles.root_background_fade}
                style = {{
                    marginLeft: (window_width <= max_width ? "0": "calc(((100vw - var(--max_width)) / 2) * -1)"),
                }}/>
            {/*<div className = {styles.suptitle}>
                {json_data.suptitle}
            </div>*/}
            <div className = {styles.title}>
                {json_data.title}
            </div>

            <div className = {styles.team_elements_super_div}>
                {members.map((team_slice) => (
                    <div 
                        key = {members.indexOf(team_slice)}
                        className = {styles.team_elements_div}>
                        <div className = {styles.team_elements_title}>
                            {json_data.team_tabs_titles[members.indexOf(team_slice)]}
                        </div>
                        <div className = {styles.team_elements_inner_div}>
                            {team_slice.map((item) => (
                                <div 
                                    key = {team_slice.indexOf(item)}
                                    className = {isTeamMemberAnAdvisor(item) ? styles.team_element_div_black : styles.team_element_div}
                                    style = {{
                                        minWidth: (window_width < 400 ? "150px" : "180px"),
                                    }}>
                                    <div 
                                        title = {isTeamMemberAnAdvisor(item) ? json_data.advisory_board_tag_hint : json_data.management_board_tag_hint}
                                        className = {styles.team_element_tag}
                                        style = {{
                                            fontSize: (window_width < 450 ? "10px" : "12px"),
                                            display: (item.tag === "" ? "none" : ""),
                                            border: (isTeamMemberAnAdvisor(item) ? "1px solid rgba(150, 150, 150, 1.0)" : "1px solid rgba(230, 180, 180, 1.0)"),
                                            color: (isTeamMemberAnAdvisor(item) ? "rgba(30, 30, 30, 1.0)" : "var(--secondary_colour)"),
                                        }}>
                                        {item.tag}
                                    </div>

                                    <div className = {styles.team_element_first_part}>
                                        <div 
                                            className = {styles.team_element_cover_image} 
                                            style = {{
                                                backgroundImage: `url(${require(`${isTeamMemberAnAdvisor(item) ? (json_data.cover_image.replace(".svg", "_black.svg")) : json_data.cover_image}`)})`, 
                                                backgroundPosition: "center", 
                                                backgroundRepeat: "repeat", 
                                                backgroundSize: "cover"
                                            }}>
                                        </div>
                                        
                                        <div className = {styles.team_element_image_div}>
                                            <div 
                                                className = {styles.team_element_image}
                                                style = {{
                                                    border: ("1px solid " + (isTeamMemberAnAdvisor(item) ? "rgba(30, 30, 30, 1.0)" : "var(--secondary_colour)")),
                                                    boxShadow: (isTeamMemberAnAdvisor(item) ? "rgb(90, 90, 90) 0px 15px 30px -10px" : "rgb(171, 83, 83) 0px 15px 30px -10px"),
                                                    height: (window_width < 600 ? (window_width < 400 ? "70px" : "80px") : "95px"),
                                                    width: (window_width < 600 ? (window_width < 400 ? "70px" : "80px") : "95px"),
                                                    backgroundImage: `url(${require(`${item.image}`)})`,
                                                    backgroundPosition: "center", 
                                                    backgroundRepeat: "no-repeat", 
                                                    backgroundSize: "cover",
                                                }}
                                            >
                                            </div>
                                        </div>

                                        <div 
                                            className = {styles.team_element_url_icon_div} 
                                            style = {{
                                                //boxShadow: (isTeamMemberAnAdvisor(item) ? "rgb(90, 90, 90) 0px 15px 30px -10px" : "rgb(171, 83, 83) 0px 15px 30px -10px"),
                                                border: (isTeamMemberAnAdvisor(item) ? "1px solid rgba(150, 150, 150, 1.0)" : "1px solid rgba(230, 180, 180, 1.0)"),
                                            }}
                                            onClick = {() => handle_click(item.url)}>
                                            <div className = {styles.vertical_align}>
                                                <div 
                                                    className = {styles.team_element_url_icon}
                                                    style = {{
                                                        backgroundImage: `url(${require(`${isTeamMemberAnAdvisor(item) ? item.url_icon.replace(".png", "_black.png") : item.url_icon}`)})`,
                                                        backgroundPosition: "center", 
                                                        backgroundRepeat: "no-repeat", 
                                                        backgroundSize: "cover",
                                                    }}
                                                />
                                            </div>
                                        </div>

                                        <div className = {styles.team_element_separator_div}>
                                            <div 
                                                className = {styles.team_element_separator_line}
                                                style = {{
                                                    background: (isTeamMemberAnAdvisor(item) ? "linear-gradient(90deg, rgba(255,255,255,1) 2%, rgb(35, 35, 35) 65%)" : "linear-gradient(90deg, rgba(255,255,255,1) 2%, rgba(116,4,0,1) 65%)"),
                                                }}/>
                                            <div 
                                                className = {styles.team_element_separator} 
                                                style = {{
                                                    backgroundImage: `url(${require(`${isTeamMemberAnAdvisor(item) ? json_data.criam_logo_separator.replace(".png", "_black.png") : json_data.criam_logo_separator}`)})`, 
                                                    backgroundPosition: "center", 
                                                    backgroundRepeat: "no-repeat", 
                                                    backgroundSize: "contain"
                                                }}>
                                            </div>
                                            <div 
                                                className = {styles.team_element_separator_line}
                                                style = {{
                                                    background: (isTeamMemberAnAdvisor(item) ? "linear-gradient(-90deg, rgba(255,255,255,1) 2%, rgb(35, 35, 35) 65%)" : "linear-gradient(-90deg, rgba(255,255,255,1) 2%, rgba(116,4,0,1) 65%)"),
                                                }}/>
                                        </div>
                                        <div 
                                            className = {styles.team_element_name}
                                            style = {{
                                                fontSize: (window_width < 400 ? "13px" : "17px"),
                                                color: (isTeamMemberAnAdvisor(item) ? "rgba(50, 50, 50, 1.0)" : "var(--secondary_colour)"),
                                            }}>
                                            {item.name}
                                        </div>
                                    </div>

                                    <div className = {styles.team_element_second_part}>
                                        <div className = {styles.vertical_align}>
                                            <div className = {styles.team_element_role_div}>
                                                <div className = {styles.vertical_align}>
                                                    <div 
                                                        className = {styles.team_element_role}
                                                        style = {{
                                                            fontSize: (window_width < 400 ? "14px" : "15px"),
                                                            display: (members.indexOf(team_slice) === 1 ? "none" : ""),
                                                        }}>
                                                        {item.role}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>  
                    </div>  
                ))}
            </div>              
        </div> 
    );
}

export default Team;