import React from 'react';
import ReactDOM from 'react-dom';
import { useEffect, useState, useRef } from 'react';
import styles from './styles/technology.module.scss';
import json_technology_en from './jsons/en/technology_en.json';
import json_technology_pt from './jsons/pt/technology_pt.json';

const Technology = () => {

    //////////////////////////////////////////////////////////////////////////
    // VARIABLES
    //////////////////////////////////////////////////////////////////////////
    // json data
    const [language, set_language] = useState(localStorage.getItem("language"));
    const json_data = language === "EN" ? json_technology_en : json_technology_pt;
    const [window_width, set_window_width] = useState(window.innerWidth);
    //const max_width = parseInt(getComputedStyle(document.body).getPropertyValue('--max_width').split("px")[0]);
    
    const main_highlights_div_ref = useRef(null);
    
    // main highlights div
    const [main_highlights_div_ref_state, set_main_highlights_div_ref_state] = useState(null);
    
    // first highlight div
    const first_highlight_dot_ref = useRef(null);
    const first_highlight_div_ref = useRef(null);
    const [first_highlight_is_visible, set_first_highlight_is_visible] = useState(false);
    const [first_highlight_dot_ref_state, set_first_highlight_dot_ref_state] = useState(null);
    const [first_highlight_div_ref_state, set_first_highlight_div_ref_state] = useState(null);

    // second highlight div
    const second_highlight_dot_ref = useRef(null);
    const second_highlight_div_ref = useRef(null);
    const [second_highlight_is_visible, set_second_highlight_is_visible] = useState(false);
    const [second_highlight_dot_ref_state, set_second_highlight_dot_ref_state] = useState(null);
    const [second_highlight_div_ref_state, set_second_highlight_div_ref_state] = useState(null);

    // third highlight div
    const third_highlight_dot_ref = useRef(null);
    const third_highlight_div_ref = useRef(null);
    const [third_highlight_is_visible, set_third_highlight_is_visible] = useState(false);
    const [third_highlight_dot_ref_state, set_third_highlight_dot_ref_state] = useState(null);
    const [third_highlight_div_ref_state, set_third_highlight_div_ref_state] = useState(null);

    // listen for language changes
    window.addEventListener('language', () => {
        set_language(localStorage.getItem("language"));
    });

    // listen for window width changes
    window.addEventListener('resize', () => { 
        set_window_width(window.innerWidth); 
    });

    useEffect(() => {
        set_main_highlights_div_ref_state(ReactDOM.findDOMNode(main_highlights_div_ref.current));

        set_first_highlight_dot_ref_state(ReactDOM.findDOMNode(first_highlight_dot_ref.current));
        set_first_highlight_div_ref_state(ReactDOM.findDOMNode(first_highlight_div_ref.current));
        
        set_second_highlight_dot_ref_state(ReactDOM.findDOMNode(second_highlight_dot_ref.current));
        set_second_highlight_div_ref_state(ReactDOM.findDOMNode(second_highlight_div_ref.current));

        set_third_highlight_dot_ref_state(ReactDOM.findDOMNode(third_highlight_dot_ref.current));
        set_third_highlight_div_ref_state(ReactDOM.findDOMNode(third_highlight_div_ref.current));
    }, []);

    return (
        <div className = {styles.root}>
            <div className = {styles.root_inner}>

                <div className = {styles.suptitle}>
                    {json_data.suptitle}
                </div>
                <div 
                    className = {styles.first_title}
                    style = {{
                        fontSize: (window_width < 750 ? "35px" : "40px"),
                    }}>
                    {json_data.first_title}
                </div>

                <div className = {styles.product_images_and_description}>

                    <div 
                        className = {styles.product_description_super_div}
                        style = {{
                            flexBasis: (window_width < 1000 ? "5%" : "10%"),
                        }}>
                        {json_data.product_highlights.slice(0, 2).map((item) => (
                            <div 
                                key = {json_data.product_highlights.indexOf(item)}
                                className = {styles.product_description_div}
                                style = {{
                                    backgroundColor: item.background_color,
                                    flexBasis: (window_width < 1000 ? "20%" : "30%"),
                                    backgroundImage: `url(${require(`${item.image}`)})`,
                                    backgroundPosition: "center", 
                                    backgroundRepeat: "no-repeat", 
                                    backgroundSize: "cover",
                                    filter: "saturate(35%)",
                                }}>
                            </div>
                        ))}
                    </div>
                
                    <div 
                        className = {styles.product_image_div_main}
                        ref = {main_highlights_div_ref}
                        style = {{
                            height: (window_width < 1000 ? "350px" : ""),
                            flexBasis: (window_width < 1000 ? "100%" : "20%"),
                        }}>
                        <div className = {styles.product_background_image} />
                        <div 
                            className = {styles.product_main_image} 
                            style = {{
                                backgroundImage: `url(${require(`${json_data.product_highlights[2].image}`)})`,
                                backgroundPosition: "center", 
                                backgroundRepeat: "no-repeat", 
                                backgroundSize: "cover",
                            }}
                        />

                        <div 
                            className = {styles.highlight_dot}
                            ref = {first_highlight_dot_ref}
                            onClick = {() => set_first_highlight_is_visible(true)}
                            onMouseEnter = {() => set_first_highlight_is_visible(true)}
                            onMouseLeave = {() => set_first_highlight_is_visible(false)}
                            style = {{
                                top: "55px",
                                right: "0",
                                margin: "0 auto",
                                left: "-100px",
                            }}>
                            <div className = {styles.highlight_dot_inner}>
                                <div className = {styles.vertical_align}>
                                    <div className = {styles.highlight_dot_text}>
                                        {"+"}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div 
                            className = {styles.highlight_div}
                            onMouseLeave = {() => set_first_highlight_is_visible(false)}
                            ref = {first_highlight_div_ref}
                            style = {{
                                visibility: (first_highlight_is_visible ? "visible" : "hidden"),
                                opacity: (first_highlight_is_visible ? "1.0" : "0.0"),
                                left: (first_highlight_dot_ref_state !== null ? (Math.max(first_highlight_dot_ref_state.getBoundingClientRect().x - main_highlights_div_ref_state.getBoundingClientRect().x - (first_highlight_div_ref_state.getBoundingClientRect().width / 2 - 10), 0) + "px") : "0px"),
                                top: (first_highlight_div_ref_state !== null ? (first_highlight_dot_ref_state.getBoundingClientRect().y - main_highlights_div_ref_state.getBoundingClientRect().y - (first_highlight_div_ref_state.getBoundingClientRect().height + 10) + "px") : "0px"),
                            }}>
                            <div className = {styles.highlight_cover_image} />
                            <div className = {styles.highlight_div_title}>
                                {json_data.product_highlights[2].first_highlight}
                            </div>
                            <div className = {styles.highlight_div_description}>
                                {json_data.product_highlights[2].first_highlight_description}
                            </div>
                        </div>

                        <div 
                            className = {styles.highlight_dot}
                            ref = {second_highlight_dot_ref}
                            onClick = {() => set_second_highlight_is_visible(true)}
                            onMouseEnter = {() => set_second_highlight_is_visible(true)}
                            onMouseLeave = {() => set_second_highlight_is_visible(false)}
                            style = {{
                                top: "200px",
                                right: "0",
                                margin: "0 auto",
                                left: "-200px",
                            }}>
                            <div className = {styles.highlight_dot_inner}>
                                <div className = {styles.vertical_align}>
                                    <div className = {styles.highlight_dot_text}>
                                        {"+"}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div 
                            className = {styles.highlight_div}
                            onMouseLeave = {() => set_second_highlight_is_visible(false)}
                            ref = {second_highlight_div_ref}
                            style = {{
                                visibility: (second_highlight_is_visible ? "visible" : "hidden"),
                                opacity: (second_highlight_is_visible ? "1.0" : "0.0"),
                                left: (second_highlight_dot_ref_state !== null ? (Math.max(second_highlight_dot_ref_state.getBoundingClientRect().x - main_highlights_div_ref_state.getBoundingClientRect().x - (second_highlight_div_ref_state.getBoundingClientRect().width / 2 - 10), 0) + "px") : "0px"),
                                top: (second_highlight_div_ref_state !== null ? (second_highlight_dot_ref_state.getBoundingClientRect().y - main_highlights_div_ref_state.getBoundingClientRect().y - (second_highlight_div_ref_state.getBoundingClientRect().height + 10) + "px") : "0px"),
                            }}>
                            <div className = {styles.highlight_cover_image} />
                            <div className = {styles.highlight_div_title}>
                                {json_data.product_highlights[2].second_highlight}
                            </div>
                            <div className = {styles.highlight_div_description}>
                                {json_data.product_highlights[2].second_highlight_description}
                            </div>
                        </div>

                        <div 
                            className = {styles.highlight_dot}
                            ref = {third_highlight_dot_ref}
                            onClick = {() => set_third_highlight_is_visible(true)}
                            onMouseEnter = {() => set_third_highlight_is_visible(true)}
                            onMouseLeave = {() => set_third_highlight_is_visible(false)}
                            style = {{
                                top: "300px",
                                right: "0",
                                margin: "0 auto",
                                left: "50px",
                            }}>
                            <div className = {styles.highlight_dot_inner}>
                                <div className = {styles.vertical_align}>
                                    <div className = {styles.highlight_dot_text}>
                                        {"+"}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div 
                            className = {styles.highlight_div}
                            onMouseLeave = {() => set_third_highlight_is_visible(false)}
                            ref = {third_highlight_div_ref}
                            style = {{
                                visibility: (third_highlight_is_visible ? "visible" : "hidden"),
                                opacity: (third_highlight_is_visible ? "1.0" : "0.0"),
                                left: (third_highlight_dot_ref_state !== null ? (Math.max(third_highlight_dot_ref_state.getBoundingClientRect().x - main_highlights_div_ref_state.getBoundingClientRect().x - (third_highlight_div_ref_state.getBoundingClientRect().width / 2 - 10), 0) + "px") : "0px"),
                                top: (third_highlight_div_ref_state !== null ? (third_highlight_dot_ref_state.getBoundingClientRect().y - main_highlights_div_ref_state.getBoundingClientRect().y - (third_highlight_div_ref_state.getBoundingClientRect().height + 10) + "px") : "0px"),
                            }}>
                            <div className = {styles.highlight_cover_image} />
                            <div className = {styles.highlight_div_title}>
                                {json_data.product_highlights[2].third_highlight}
                            </div>
                            <div className = {styles.highlight_div_description}>
                                {json_data.product_highlights[2].third_highlight_description}
                            </div>
                        </div>

                    </div>
                    
                    <div 
                        className = {styles.product_description_super_div}
                        style = {{
                            flexBasis: (window_width < 1000 ? "5%" : "10%"),
                        }}>
                        {json_data.product_highlights.slice(3, json_data.product_highlights.length).map((item) => (
                            <div 
                            key = {json_data.product_highlights.indexOf(item)}
                            className = {styles.product_description_div}
                            style = {{
                                backgroundColor: item.background_color,
                                flexBasis: (window_width < 1000 ? "20%" : "30%"),
                                backgroundImage: `url(${require(`${item.image}`)})`,
                                backgroundPosition: "center", 
                                backgroundRepeat: "no-repeat", 
                                backgroundSize: "cover",
                                filter: "saturate(35%)",
                            }}>
                            {/*<div className = {styles.product_description_title}>{item.title}</div>*/}
                        </div>
                        ))}
                    </div>
                </div>
                
                <div 
                    className = {styles.second_title}
                    style = {{
                        fontSize: (window_width < 750 ? "35px" : "40px"),
                    }}>
                    {json_data.second_title}
                </div>

                <div className = {styles.steps_div}>
                    {json_data.steps_items.map((item) => (
                        <div 
                            key = {json_data.steps_items.indexOf(item)}
                            className = {styles.step_div}
                            style = {{
                                flexBasis: (window_width < 750 ? "100%" : ""),
                            }}
                            >
                            
                            <div className = {styles.step_first_part}>
                                <div className = {styles.step_image} 
                                style = {{
                                    backgroundImage: `url(${require(`${item.image}`)})`,
                                    backgroundPosition: "center", 
                                    backgroundRepeat: "no-repeat", 
                                    backgroundSize: "cover",
                                }}/>

                                <div 
                                    className = {styles.step_separator_div}
                                    style = {{
                                        width: (window_width < 1112 ? "90%" : "85%"),
                                    }}>
                                    <div className = {styles.step_separator_line}/>
                                    <div className = {styles.step_number}>
                                        {"#" + (json_data.steps_items.indexOf(item) + 1)}
                                    </div>
                                    <div className = {styles.step_separator_line}/>
                                </div>
                                
                                <div className = {styles.step_title}>
                                    {item.title}
                                </div>
                            </div>
                                
                            <div className = {styles.step_second_part}>
                                <div className = {styles.vertical_align}>
                                    <div className = {styles.step_description}>
                                        {item.description}
                                    </div>
                                </div>
                            </div>

                        </div>
                    ))}
                </div>

                <div className = {styles.technology_div}>
                    {json_data.numbers_items.map((item) => (
                        <div 
                            key = {json_data.numbers_items.indexOf(item)}
                            className = {styles.technology_inner_div}
                            style = {{
                                //borderRight: (json_data.numbers_items.indexOf(item) !== (json_data.numbers_items.length - 1) ? (window_width < 450 ? "" : "1px solid rgba(100, 100, 100, 0.25)") : "")
                            }}>
                            <div 
                                className = {styles.number_div}
                                style = {{
                                    fontSize: (window_width < 700 ? "22px" : "40px"),
                                }}>
                                {item.number}
                            </div>
                            <div 
                                className = {styles.description_div}
                                style = {{
                                    fontSize: (window_width < 700 ? "13.5px" : "20px"),
                                }}>
                                {item.description}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Technology;