import React from 'react';
import { useState } from 'react';
import styles from './styles/mission.module.scss';
import json_mission_en from './jsons/en/mission_en.json';
import json_mission_pt from './jsons/pt/mission_pt.json';

const Mission = () => {

    //////////////////////////////////////////////////////////////////////////
    // VARIABLES
    //////////////////////////////////////////////////////////////////////////
    // json data
    const [language, set_language] = useState(localStorage.getItem("language"));
    const json_data = language === "EN" ? json_mission_en : json_mission_pt;
    const [window_width, set_window_width] = useState(window.innerWidth);
    const max_width = parseInt(getComputedStyle(document.body).getPropertyValue('--max_width').split("px")[0]);

    // listen for language changes
    window.addEventListener('language', () => {
        set_language(localStorage.getItem("language"));
    });

    // listen for window width changes
    window.addEventListener('resize', () => { 
        set_window_width(window.innerWidth); 
    });
    
    return ( 
        <div className = {styles.root}>
            <div 
                className = {styles.mission_div_background} 
                style = {{
                    marginLeft: (window_width <= max_width ? "0": "calc(((100vw - var(--max_width)) / 2) * -1)"),
                }}/>
            <div 
                className = {styles.mission_div_background_fade}
                style = {{
                    marginLeft: (window_width <= max_width ? "0": "calc(((100vw - var(--max_width)) / 2) * -1)"),
                }}/>
            <div 
                className = {styles.mission_div}
                style = {{
                    marginLeft: (window_width <= max_width ? "0": "calc(((100vw - var(--max_width)) / 2) * -1)"),
                }}>
                {/*<div className = {styles.suptitle}>
                    {json_data.suptitle}
                </div>*/}
                <div className = {styles.title_div}>
                    <div className = {styles.vertical_align}>
                        <span 
                            className = {styles.title}
                            style = {{
                                color: "rgba(90, 90, 90, 1.0)",
                                fontSize: (window_width < 900 ? "20px" : "32px"),
                            }}>
                            {json_data.title_first_part}
                        </span>
                        <span 
                            className = {styles.title}
                            style = {{
                                color: "var(--secondary_colour)",
                                fontSize: (window_width < 900 ? "20px" : "32px"),
                            }}>
                            {json_data.title_second_part}
                        </span>
                        <span 
                            className = {styles.title}
                            style = {{
                                color: "rgba(90, 90, 90, 1.0)",
                                fontSize: (window_width < 900 ? "20px" : "32px"),
                            }}>
                            {json_data.title_third_part}
                        </span>
                        <span 
                            className = {styles.title}
                            style = {{
                                color: "var(--secondary_colour)",
                                fontSize: (window_width < 900 ? "20px" : "32px"),
                            }}>
                            {json_data.title_fourth_part}
                        </span>
                        <span 
                            className = {styles.title}
                            style = {{
                                color: "rgba(90, 90, 90, 1.0)",
                                fontSize: (window_width < 900 ? "20px" : "32px"),
                            }}>
                            {json_data.title_fifth_part}
                        </span>
                        <div 
                            className = {styles.extra_details_div}
                            style = {{
                                fontSize: (window_width < 900 ? "17px" : "23px"),
                            }}>
                            {json_data.extra_details}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Mission;