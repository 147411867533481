import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import styles from './styles/footer.module.scss';
import json_footer_en from './jsons/en/footer_en.json';
import json_footer_pt from './jsons/pt/footer_pt.json';
import Socials from './Socials';

const Footer = () => {

    //////////////////////////////////////////////////////////////////////////////
    // VARIABLES
    //////////////////////////////////////////////////////////////////////////////
    // json data
    const [language, set_language] = useState(localStorage.getItem("language"));
    const json_data = language === "EN" ? json_footer_en : json_footer_pt;
    const [window_width, set_window_width] = useState(window.innerWidth);
    let history = useHistory();
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    
    // listen for language changes
    window.addEventListener('language', () => {
        set_language(localStorage.getItem("language"));
    });

    // listen for window width changes
    window.addEventListener('resize', () => { 
        set_window_width(window.innerWidth); 
    });

    ////////////////////////////////////////////
    // AUXILIARY FUNCTIONS
    ////////////////////////////////////////////
    // ------------------------------------
    // redirect the user to the chosen page
    // ------------------------------------
    const handle_click = (link) => {

        if(link === "home"){
            history.push("/");
            window.scrollTo(0, 0);
            forceUpdate();
        }

        else if(link === "email")
            window.open("mailto:" + json_data.right.description[2].text + "?subject=&body=", "_self")
    }

    return ( 
        <div 
            className = {styles.desktop_super_root}
            style = {{
                height: (window_width < 1050 ? (window_width < 602 ? (window_width < 550 ? (window_width < 330 ? "650px" : "600px") : "550px") : "480px") : "460px"),
            }}>
            {/*<div className = {styles.footer_background_fade} />*/}
            <div 
                className = {styles.footer_background} 
                style = {{
                    backgroundImage: (window_width < 725 ? `url(${require(`${json_data.background_image_mobile}`)})` : `url(${require(`${json_data.background_image}`)})`),
                    backgroundPosition: (window_width < 725 ? "top" : "center"),
                }}/>
            
            <div className = {styles.desktop_root}>
                <div className = {styles.vertical_align_super}>
                    <div className = {styles.desktop_root_inner}>
                        <div className = {styles.desktop_root_main_inner}>
                        
                            <div 
                                className = {styles.left_block}
                                style = {{
                                    flexGrow: (window_width < 765 ? (window_width < 602 ? "1" : "2") : "1"),
                                    paddingLeft: (window_width < 735 ? "0px" : "10px"),
                                }}>
                                <div className = {styles.vertical_align} style = {{height: "100%", width: "100%"}}>
                                    <div className = {styles.left_block_inner}>
                                        <div className = {styles.top}>
                                            <div className = {styles.logo_div}>
                                                <div 
                                                    onClick = {() => handle_click("home")}
                                                    className = {styles.logo} 
                                                    style = {{backgroundImage: `url(${require(`${json_data.left.alternative_logo}`)})`, backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "contain"}}
                                                />
                                            </div>
                                            {/*<span className = {styles.main_title} onClick = {() => handle_click("home")} dangerouslySetInnerHTML={{__html: json_data.left.title}}/>*/}
                                            {/*<span className = {styles.version} dangerouslySetInnerHTML={{__html: json_data.left.version}} />*/}
                                        </div>
                                        <div className = {styles.main_legal_text} dangerouslySetInnerHTML={{__html: json_data.bottom.copyright_text.replace("YEAR", new Date().getFullYear().toString())}} />
                                    </div>
                                </div>
                            </div>

                            <div className = {styles.middle_block}>
                                <div className = {styles.socials_div}>
                                    <Socials />
                                </div>
                            </div>
                            
                            <div className = {styles.right_block}>
                                <div className = {styles.vertical_align_right}>
                                    <div className = {styles.inner_div}>
                                        {/*<div className = {styles.block_title}>{json_data.right.title}</div>*/}
                                        {json_data.right.description.map((description_item) => (
                                            <div 
                                                key = {json_data.right.description.indexOf(description_item)}
                                                className = {styles.contacts_line}>
                                                <div className = {styles.contacts_title}>{description_item.title}</div>
                                                <div className = {styles.contacts_separator}>{description_item.separator}</div>
                                                <div 
                                                    className = {styles.contacts_text}
                                                    style = {{
                                                        cursor: (json_data.right.description.indexOf(description_item) === (json_data.right.description.length - 1) ? "pointer" : "normal")
                                                    }}
                                                    onClick = {() => handle_click(json_data.right.description.indexOf(description_item) === (json_data.right.description.length - 1) ? "email" : "")}
                                                    dangerouslySetInnerHTML = {{__html: description_item.text}} />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>

                        </div>
                        
                        <div className = {styles.bottom_div}>
                            <div className = {styles.horizontal_line}/>
                            <div className = {styles.partners_div}>
                                {json_data.middle.items.map((item) => (
                                    <div 
                                        key = {json_data.middle.items.indexOf(item)}
                                        className = {styles.partner_item_inner_div}
                                        style = {{
                                            minWidth: (window_width < 550 ? "100px": ""),
                                        }}>
                                        <div 
                                            className = {styles.partner_image} 
                                            title = {item[0]}
                                            style = {{backgroundImage: `url(${require(`${item[1]}`)})`, backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "contain"}}>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;