import Slider from 'infinite-react-carousel';
import styles from './styles/carousel.module.scss';
import React, { useEffect, useState, useRef } from 'react';
import json_carousel_en from './jsons/en/carousel_en.json';
import json_carousel_pt from './jsons/pt/carousel_pt.json';

const Carousel = (props) => {

    //////////////////////////////////////////////////////////////////////////////////////
    // VARIABLES
    //////////////////////////////////////////////////////////////////////////////////////
    // json data
    const [language, set_language] = useState(localStorage.getItem("language"));
    const json_data = language === "EN" ? json_carousel_en : json_carousel_pt;
    const [data, set_data] = useState(props.data);
    const [arrows_have_been_clicked, set_arrows_have_been_clicked] = useState(false);
    const [arrow_toggle_being_hovered, set_arrow_toggle_being_hovered] = useState(null);
    const [window_width, set_window_width] = useState(window.innerWidth);
    const slider_ref = useRef();

    // listen for language changes
    window.addEventListener('language', () => {
        set_language(localStorage.getItem("language"));
    });

    // listen for window width changes
    window.addEventListener('resize', () => { 
        set_window_width(window.innerWidth); 
    });

    useEffect(() => {
        set_data(props.data);
    }, [props.data]);

    const handle_click = (url) => {
        window.open(url, "__blank");
    }
    
    const handle_toggle = (action) => {

        if(slider_ref.current !== null && action === "pause"){
            set_arrows_have_been_clicked(true);
            slider_ref.current.slickPause();
        }

        else if(slider_ref.current !== null && action === "resume"){
            
            setTimeout(() => {
                set_arrows_have_been_clicked(false);
            }, 1000);

            slider_ref.current.slickPlay();
        }

        else{

            if(slider_ref.current !== null && action === "right")
            slider_ref.current.slickNext();
            
            else if(slider_ref.current !== null && action === "left")
            slider_ref.current.slickPrev();
            
            set_arrows_have_been_clicked(true);
        }
    }

    const settings =  {
        arrows: false,
        arrowsBlock: true,
        arrowsScroll: 2,
        rows: (window_width < 550 ? 2 : 1),
        autoplay: (arrows_have_been_clicked ? false : true),
        autoplaySpeed: 3000,
        duration: 1000,
        slidesToShow: (window_width < 1000 ? (window_width < 550 ? 2 : (window_width < 750 ? 3 : 4)) : (window_width < 1200 ? 6 : 7)),
    };

    return (
        <div className = {styles.root}>
            <div className = {styles.carousel_div}>
                {props.can_render_carousel && <Slider { ...settings } ref = {slider_ref}>
                    {data.items.map((item) => (
                        props.type === "frosted_glass" ?
                        <div 
                            key = {data.items.indexOf(item)}
                            className = {styles.carousel_item_div}>
                            <div 
                                className = {styles.carousel_item_inner_div}
                                onClick = {() => handle_click(item[2])}
                                style = {{
                                    backgroundColor: props.background_color,
                                }}>
                                <div 
                                    onClick = {() => handle_click(item[2])}
                                    className = {styles.carousel_image} 
                                    title = {item[0]}
                                    style = {{
                                        backgroundImage: `url(${require(`${item[1]}`)})`, 
                                        backgroundPosition: "center", 
                                        backgroundRepeat: "no-repeat", 
                                        backgroundSize: "contain"}}>
                                </div>
                                <div className = {styles.carousel_separator}>
                                    <div className = {styles.carousel_separator_inner} />
                                </div>
                                <div className = {styles.carousel_text_div}>
                                    <div className = {styles.carousel_text}>
                                        <div className = {styles.vertical_align}>
                                            <div 
                                                className = {styles.carousel_text_inner} 
                                                dangerouslySetInnerHTML = {{__html: item[0]}}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <div 
                            key = {data.items.indexOf(item)}
                            className = {styles.alternative_item_div}>
                            <div 
                                className = {styles.alternative_item_inner_div}
                                style = {{
                                    height: (window_width < 550 ? "90%" : "100%"),
                                    marginTop: (window_width < 550 ? "10px" : ""),
                                }}>
                                <img 
                                    className = {styles.alternative_item_image} 
                                    src = {require(`${item[1]}`)} 
                                    alt = {item[0]} 
                                    key = {data.items.indexOf(item)}
                                />
                                <div className = {styles.alternative_item_fade_div} />
                                <div className = {styles.alternative_item_text_div}>
                                    <div className = {styles.vertical_align}>
                                        <div 
                                            className = {styles.alternative_item_text_div_inner}
                                            dangerouslySetInnerHTML = {{__html: item[0]}} />
                                    </div>
                                </div>

                                <div 
                                    onClick = {() => handle_click(require(`${item[2]}`))}
                                    className = {styles.alternative_item_button_div}>
                                    <div className = {styles.vertical_align}>
                                        <div className = {styles.alternative_item_button_text}>
                                            {data.button_text}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>}
            </div>
            
            <div
                onMouseEnter = {() => handle_toggle("pause")} 
                onMouseLeave = {() => handle_toggle("resume")} 
                className = {styles.toggles_div}>

                <div
                    onClick = {() => handle_toggle("left")}
                    onMouseEnter = {() => set_arrow_toggle_being_hovered("left")}
                    onMouseLeave = {() => set_arrow_toggle_being_hovered(null)}
                    className = {styles.toggle}>
                        <div
                            className = {styles.toggle_arrow} 
                            style = {{
                                backgroundImage: `url(${require(`${arrow_toggle_being_hovered === "left" ? json_data.arrow_icon_grey : json_data.arrow_icon_white}`)})`, 
                                backgroundPosition: "center", 
                                backgroundRepeat: "no-repeat", 
                                backgroundSize: "contain",
                                transform: "rotate(180deg)",
                            }}>
                        </div>
                </div>

                <div
                    onClick = {() => handle_toggle("right")}
                    onMouseEnter = {() => set_arrow_toggle_being_hovered("right")}
                    onMouseLeave = {() => set_arrow_toggle_being_hovered(null)}
                    className = {styles.toggle} >
                        <div 
                            className = {styles.toggle_arrow} 
                            style = {{
                                backgroundImage: `url(${require(`${arrow_toggle_being_hovered === "right" ? json_data.arrow_icon_grey : json_data.arrow_icon_white}`)})`, 
                                backgroundPosition: "center", 
                                backgroundRepeat: "no-repeat", 
                                backgroundSize: "contain"
                            }}>
                        </div>
                </div>

            </div>

        </div>
    );
}

export default Carousel;