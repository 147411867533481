import React, { useState } from 'react';
import styles from './styles/socials.module.scss';
import json_socials_en from './jsons/en/socials_en.json';
import json_socials_pt from './jsons/pt/socials_pt.json';

const Socials = () => {

    ////////////////////////////////////////////////////////////////////////////////
    // VARIABLES
    ////////////////////////////////////////////////////////////////////////////////
    // json data
    const [language, set_language] = useState(localStorage.getItem("language"));
    const json_data = language === "EN" ? json_socials_en : json_socials_pt;

    // listen for language changes
    window.addEventListener('language', () => {
        set_language(localStorage.getItem("language"));
    });

    const handle_click = (link) => {

        window.open(link, "_blank");
    }

    return (
        <div className = {styles.items_div}>
            {json_data.desktop.items.map((item) => (
                <div 
                    key = {json_data.desktop.items.indexOf(item)}
                    title = {item.description}
                    className = {styles.item_div} 
                    onClick = {() => handle_click(item.link)}>
                    <div className = {styles.vertical_align} style = {{height: "100%"}}>
                        <div 
                            className = {styles.item_div_icon} 
                            style = {{backgroundImage: `url(${require(`${item.icon}`)})`, backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "contain"}}>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default Socials;