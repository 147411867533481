import Carousel from './Carousel';
import React, { useEffect, useState } from 'react';
import styles from './styles/as_seen_in.module.scss';
import json_as_seen_in_en from './jsons/en/as_seen_in_en.json';
import json_as_seen_in_pt from './jsons/pt/as_seen_in_pt.json';

const AsSeenIn = () => {
    
    //////////////////////////////////////////////////////////////////////////////////////
    // VARIABLES
    //////////////////////////////////////////////////////////////////////////////////////
    // json data
    const [language, set_language] = useState(localStorage.getItem("language"));
    const json_data = language === "EN" ? json_as_seen_in_en : json_as_seen_in_pt;
    const [can_render_carousel, set_can_render_carousel] = useState(false);

    // listen for language changes
    window.addEventListener('language', () => {
        set_language(localStorage.getItem("language"));
    });
    
    useEffect(() => {
        set_can_render_carousel(true);
    }, []);
    
    return ( 
        <div className = {styles.root}>
            <div className = {styles.suptitle}>
                {json_data.suptitle}
            </div>

            <Carousel 
                type = {"frosted_glass"}
                data = {json_data}
                can_render_carousel = {can_render_carousel}
                background_color = {"rgba(255, 255, 255, 0.12)"}
            />

        </div>
    );
}

export default AsSeenIn;