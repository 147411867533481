import React, { useState } from 'react';
import styles from './styles/references.module.scss';
import json_references_en from './jsons/en/references_en.json';
import json_references_pt from './jsons/pt/references_pt.json';

const References = () => {

    //////////////////////////////////////////////////////////////////////////////
    // VARIABLES
    //////////////////////////////////////////////////////////////////////////////
    // json data
    const [language, set_language] = useState(localStorage.getItem("language"));
    const json_data = language === "EN" ? json_references_en : json_references_pt;

    // listen for language changes
    window.addEventListener('language', () => {
        set_language(localStorage.getItem("language"));
    });

    const handle_click = (link) => {
        window.open(link, "__blank");
    }

    return (
        <div className = {styles.root}>
            {json_data.items.map((item) => (
                <div 
                    key = {json_data.items.indexOf(item)}
                    className = {styles.reference_item_div}>
                    <div 
                        onClick = {() => handle_click(item.link)}
                        className = {styles.reference_item_inner_div}>
                        <span className = {styles.reference_item_symbol}>{item.symbol}</span>
                        <span className = {styles.reference_item_description}>{item.description}</span>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default References;