import React from 'react';
import Team from './Team';
import Cover from './Cover';
import Footer from './Footer';
import Roadmap from './Roadmap';
import Company from './Company';
import Mission from './Mission';
import AsSeenIn from './AsSeenIn';
import { useEffect } from 'react';
import ContactUs from './ContactUs';
import Technology from './Technology';
import References from './References';
import Applications from './Applications';
import Achievements from './Achievements';
import styles from './styles/home.module.scss';
import FarewellMessage from './FarewellMessage';
import PublicationsAndPatents from './PublicationsAndPatents';

const Home = () => {

    // -----------------------------
    // scroll to the top of the page
    // -----------------------------
    useEffect(() => {
        window.scrollTo(0, 0);

    }, []);

    return ( 
        <div className = {styles.desktop_root}>
            <Cover />

            <Mission />
            
            <div id = "technology">
                <Technology />
            </div>
            
            <AsSeenIn />

            <div id = "applications">
                <Applications />
            </div>

            <Achievements />

            <div id = "company">
                <Company />
            </div>

            <div id = "publications">
                <PublicationsAndPatents />
            </div>
            
            <div id = "team">
                <Team />
            </div>

            <Roadmap />

            <div id = "contact_us">
                <ContactUs />
            </div>

            <FarewellMessage />

            <div id = "references">
                <References />
            </div>
            
            <Footer />
        </div>
    );
}

export default Home;